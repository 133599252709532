import React from 'react';
import { Helmet } from 'react-helmet-async';
import NetworkSites from './NetworkSites';
import useRouter from '../../hooks/useRouter';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Results from '../_common_results/Results';
import NetworkSite from './NetworkSite';
import Filters from '../../forms/filters_projects';
import { BuildingOffice2Icon, FolderIcon } from '@heroicons/react/24/outline';
import MobileNavigation from './MobileNavigation';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';
import SecondaryAction from '../_components/actions/SecondaryAction';
import { useUser } from '@lore/auth';
import useNetwork from '../../hooks/useNetwork';

export default function Layout(props) {
  const router = useRouter();
  const launch = useDialogLauncher();
  const currentUser = useUser();
  const network = useNetwork();

  function onClick() {
    launch(DialogManager.NetworkSiteCreate.Config(network.id));
  }

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Sites</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Explore', 'Sites']}
        title="All Sites"
        description={(
          <>
            This page shows all sites in your network. Use the filters to narrow the
            list. Find a filter you want to see again in the future? Bookmark the page
            or save a link to the URL.
          </>
        )}
      >
        {currentUser.data.isSuperuser && (
          <div className="self-start">
            <SecondaryAction onClick={onClick} secret={true} disabled={!network}>
              Add Site
            </SecondaryAction>
          </div>
        )}
      </PageHeading>
      <Filters router={router} />
      <NetworkSites router={router}>
        {function(result) {
          return (
            <Results result={result} columns={1} resource="sites" emptyIcon={BuildingOffice2Icon}>
              {function(site, index) {
                return (
                  <NetworkSite
                    key={index}
                    network={network}
                    site={site}
                  />
                );
              }}
            </Results>
          );
        }}
      </NetworkSites>
    </div>
  );
}
