import React from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import useNetwork from '../../../hooks/useNetwork';
import useGetCount from '../../explore/useGetCount';

// Partners
import relatedPartners from '../../partners-filtered/related/useQuery';
import starredPartners from '../../partners-filtered/starred/useQuery';
import incompletePartners from '../../partners-filtered/incomplete/useQuery';
import newPartners from '../../partners-filtered/new/useQuery';
import sleepingPartners from '../../partners-filtered/sleeping/useQuery';
import inactivePartners from '../../partners-filtered/inactive/useQuery';
import noInteractionsPartners from '../../partners-filtered/no-interactions/useQuery';
import multipleEvaluationsPartners from '../../partners-filtered/multiple-evaluations/useQuery';
import multipleHoursPartners from '../../partners-filtered/multiple-hours/useQuery';
import multipleStudentsPartners from '../../partners-filtered/multiple-students/useQuery';
import multipleDonationsPartners from '../../partners-filtered/multiple-donations/useQuery';

export default function getNavigationLinks() {
  const currentUser = useUser();

  return _.remove([
    { name: 'All', href: `/partners` },
    { name: 'Site-related', href: `/partners/related`, count: useGetCount(relatedPartners) },
    currentUser.data.isSuperuser ?
      { name: '[Engagement]', href: `/partners/engagement` } :
      null,
    { name: 'Starred', href: `/partners/starred`, count: useGetCount(starredPartners) },
    { name: 'New', href: `/partners/new`, count: useGetCount(newPartners), highlight: true },
    { name: 'Sleeping', href: `/partners/sleeping`, count: useGetCount(sleepingPartners) },
    { name: 'Inactive', href: `/partners/inactive`, count: useGetCount(inactivePartners) },
    { name: 'Incomplete Profile', href: `/partners/incomplete`, count: useGetCount(incompletePartners) },
    { name: 'No Interactions', href: `/partners/no-interactions`, count: useGetCount(noInteractionsPartners) },
    { name: '3+ Evaluations', href: `/partners/multiple-evaluations`, count: useGetCount(multipleEvaluationsPartners) },
    { name: '25+ Hours Served', href: `/partners/multiple-hours`, count: useGetCount(multipleHoursPartners) },
    { name: '50+ Students Served', href: `/partners/multiple-students`, count: useGetCount(multipleStudentsPartners) },
    { name: '$50+ Donations', href: `/partners/multiple-donations`, count: useGetCount(multipleDonationsPartners) }
  ]);
}
