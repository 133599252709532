import React from 'react';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';
import useOrganizations from './_hooks/useOrganizations';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';
import useSchools from '../Schools/_hooks/useSchools';

export default function CompositeOrganizationGrowth(props) {
  const { network, schoolStart, schoolEnd, titleOnly } = props;

  const previousSchoolStart = titleOnly ? undefined : moment(schoolStart).subtract(1, 'year').toISOString();

  const totalOrganizations = useOrganizations(network, undefined, schoolEnd);
  const previousOrganizations = useOrganizations(network, previousSchoolStart, schoolStart);
  const newOrganizations = useOrganizations(network, schoolStart, schoolEnd);
  if (
    totalOrganizations.state === PayloadStates.FETCHING ||
    previousOrganizations.state === PayloadStates.FETCHING ||
    newOrganizations.state === PayloadStates.FETCHING
  ) {
    return (
      <>
        <MetricLoader
          name="Total organization growth"
        />
        {!titleOnly && (
          <>
            <MetricLoader
              name="Organization growth"
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <DeltaMetric
        name="Total organization growth"
        value={totalOrganizations.meta.totalCount}
        delta={newOrganizations.meta.totalCount}
        titleOnly={titleOnly}
      />
      {!titleOnly && (
        <>
          <ComparisonMetric
            name="Organization growth"
            value={newOrganizations.meta.totalCount}
            previousValue={previousOrganizations.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
    </>
  );
}
