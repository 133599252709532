import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
// import SelectField from '../../../forms/_fields/SelectField';
import SelectField from '../../forms/_fields/UserSelectField';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../_common_old/FormTemplate.vertical';
import FormField from '../../forms/_common/FormField';
import Loader from '../../components/Loader';
import useNetwork from '../../hooks/useNetwork';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose
  } = props;

  const network = useNetwork();

  const users = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          'networkStewards.networkId': network.id
        }
      }
    },
    pagination: {
      order: 'firstName asc'
    }
  });

  if (users.state === PayloadStates.FETCHING) {
    return (
      <FormTemplate
        title="Assign Event"
        subtitle="Select the person you want to assign this event to."
        icon={ClockIcon}
        onClose={onClose}
        alert={alert}
        body={(
          <div className="text-center py-8">
            <Loader />
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onClose}>
              Cancel
            </OutlineButton>
            <SolidButton onClick={onSubmit}>
              Assign
            </SolidButton>
          </>
        )}
      />
    );
  }

  return (
    <FormTemplate
      title="Assign Event"
      subtitle="Select the person you want to assign this event to."
      icon={ClockIcon}
      onClose={onClose}
      alert={alert}
      body={(
        <>
          <FormField name="assigneeId" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <SelectField
                label="Assignee"
                value={field.value}
                error={field.error}
                placeholder="Select a person..."
                options={users}
                optionLabel="firstName"
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Assign
          </SolidButton>
        </>
      )}
    />
  );
}
