import React from 'react';
import {
  CalendarIcon,
  ClipboardDocumentListIcon,
  Cog6ToothIcon,
  FolderIcon,
  ListBulletIcon,
  NewspaperIcon,
  PresentationChartLineIcon,
  UsersIcon,
  AcademicCapIcon,
  BuildingOffice2Icon,
  GlobeAltIcon
} from '@heroicons/react/24/outline';
import { useUser } from '@lore/auth';
import _ from 'lodash';
import useNetwork from '../../../hooks/useNetwork';

export default function useMainNavigationLinks() {
  const currentUser = useUser();

  const urls = {
    dashboard: `/dashboard`,
    feed: `/feed`,
    sites: `/sites`,
    manage: `/manage`,
    // stewards: `/stewards`,
    projects: `/projects`,
    events: `/events`,
    offers: `/offers`,
    partners: `/partners`,
    educators: `/educators`,
    evaluations: `/evaluations`,
    settings: `/settings`,
    // team lives under settings
    team: `/team`
  };

  return _.remove([
    { name: 'Dashboard', icon: PresentationChartLineIcon, to: urls.dashboard },
    { name: '[Feed]', icon: ListBulletIcon, to: urls.feed },
    currentUser.data.isStaff ?
      { name: 'Sites', icon: BuildingOffice2Icon, to: urls.sites, secret: true } :
      null,
    { name: 'Projects', icon: FolderIcon, to: urls.projects },
    { name: 'Events', icon: CalendarIcon, to: urls.events },
    { name: 'Offers', icon: NewspaperIcon, to: urls.offers },
    { name: 'Partners', icon: UsersIcon, to: urls.partners },
    { name: 'Educators', icon: AcademicCapIcon, to: urls.educators },
    { name: 'Evaluations', icon: ClipboardDocumentListIcon, to: urls.evaluations },
    { name: 'Settings', icon: Cog6ToothIcon, to: urls.settings, startsWithOneOf: [urls.settings, urls.team] },
    currentUser.data.isStaff ?
      { name: '[Manage networks]', icon: GlobeAltIcon, to: urls.manage, startsWithOneOf: [urls.manage], secret: true } :
      null
  ]);
}
