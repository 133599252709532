import {
  AcademicCapIcon,
  BuildingLibraryIcon,
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  CalculatorIcon,
  CalendarIcon,
  ClipboardDocumentListIcon,
  FolderIcon,
  NewspaperIcon,
  UsersIcon
} from '@heroicons/react/24/outline';

export default function(network, currentUser, currentNavigationLink) {
  const root = `${currentNavigationLink.href}`;

  return [
    {
      name: `Student Impact`,
      href: `${root}/students`,
      // icon: CalculatorIcon
    },
    {
      name: `Educators`,
      href: `${root}/educators`,
      // icon: AcademicCapIcon
    },
    {
      name: `Partners`,
      href: `${root}/partners`,
      // icon: UsersIcon
    },
    {
      name: `Projects`,
      href: `${root}/projects`,
      // icon: FolderIcon
    },
    {
      name: `Events`,
      href: `${root}/events`,
      // icon: CalendarIcon
    },
    {
      name: `Offers`,
      href: `${root}/offers`,
      // icon: NewspaperIcon
    },
    {
      name: `Evaluations`,
      href: `${root}/evaluations`,
      // icon: ClipboardDocumentListIcon
    },
    {
      name: `Schools`,
      href: `${root}/schools`,
      // icon: BuildingOffice2Icon
    },
    {
      name: `Organizations`,
      href: `${root}/organizations`,
      // icon: BuildingLibraryIcon
    }
  ];
};
