import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import User from '../../_common_user/ManageUser';
import useQuery from './useQuery';
import DateJoined from '../../_common_user/User/DateJoined';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';

export default function Layout(props) {
  const { location } = useRouter();
  const launch = useDialogLauncher();

  const PAGE_SIZE = 12;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  const csvQuery = useQuery({
    location,
    PAGE_SIZE,
    returnRawQuery: true
  });

  function onDownload(query) {
    launch(DialogManager.CSVDownload.Config(query));
  }

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Partners']}
      title="Site-related"
      description={(
        <span>
          These are partners who have connected with a project or event linked to a site in your network.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      csvQuery={function () {
        onDownload(csvQuery);
      }}
      row={function(user) {
        return (
          <User key={user.id} user={user}>
            <div className="bg-cs-gray-50 p-4 border-t border-cs-gray-100">
              <DateJoined user={user} />
            </div>
          </User>
        );
      }}
    />
  );
}
