import { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { parse } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from './useStewardshipFilter';
import getQueryUtils, { attributeKeys } from './queryUtils';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 4
  } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  const queryUtils = getQueryUtils(search, attributeKeys, timestamp);

  return useConnect('offer.find', {
    where: {
      eager: {
        // $aggregations: [
        //   'Invited',
        //   'Interested',
        //   'Scheduling',
        //   'Attending'
        // ].map(function(stage) {
        //   return {
        //     type: 'count',
        //     alias: `numberOfConversations${stage}`,
        //     relation: 'conversations',
        //     $where: {
        //       stage: stage
        //     }
        //   };
        // }),
        $where: {
          $and: [...queryUtils.where],
          activityId: {
            $exists: true
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: pageSize,
      // order: search.order || 'createdAt desc'
      order: 'id desc'
    }
  }, {
    keepPreviousData : true
  });
}
