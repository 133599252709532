import React  from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ClampableText from '../../components/ClampableText';
import Avatar from '../../components/Avatar';
import logo from '../../../assets/images/logo.png';
import { getDistanceFromLatLonInMiles } from '../../components/User/utils';
import TagTypes from '../../constants/TagTypes';
import useProjectTags from '../../hooks/useProjectTags';

export default function useProjectEmailPreview(project, partner, customMessage, currentUser, network) {
  const educator = useConnect('user.byId', {
    id: project.data.creatorId
  });
  const distance = getDistanceFromLatLonInMiles(partner.data, educator.data);

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const projectInteractionTypes = useProjectTags(project, TagTypes.InteractionType);

  if (
    educator.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING ||
    projectInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  function getActivity() {
    return {
      title: `${activity.data.name}`,
      text: `${activity.data.description}`
    };
  }

  function getSummary() {
    return {
      title: `${project.data.title || activity.data.name}`,
      text: `${project.data.interactionDescription || activity.data.description}`
    };
  }

  function getDate() {
    if (
      project.data.startDate &&
      project.data.endDate
    ) {
      return {
        title: `Date Window`,
        text: `${moment(project.data.startDate).format('MMM Do, YYYY')} - ${moment(project.data.endDate).format('MMM Do, YYYY')}`
      };
    }

    return {
      title: `Date`,
      text: `(not provided)`
    };
  }

  function getInPerson() {
    return !!_.find(projectInteractionTypes.data, function(projectInteractionType) {
      return projectInteractionType.data.name === 'In-person';
    });
  }

  function getVirtual() {
    return !!_.find(projectInteractionTypes.data, function(projectInteractionType) {
      return projectInteractionType.data.name === 'Virtual';
    });
  }

  const data = {
    activity: getActivity(),
    summary: getSummary(),
    date: getDate(),
    inPerson: getInPerson(),
    virtual: getVirtual()
  };

  return (
    <div className="bg-cs-gray-old p-6">
      <div className="h-1 bg-accent w-full mb-8"/>
      <div className="p-6 mb-8">
        <img
          className="w-24 mx-auto"
          src={logo}
          alt="logo"
        />
      </div>
      <div className="mb-8">
        <div className="text-base text-gray-500">
          Hi {partner.data.firstName},
        </div>
        <div className="my-3 text-base text-gray-500">
          <ClampableText markup>
            {customMessage}
          </ClampableText>
        </div>
        <div className="mb-3">
          <div className="text-base text-gray-500">
            Cheers,
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <Avatar size={12} src={currentUser.data.avatarUrl} />
          </div>
          <div>
            <div className="text-lg text-gray-700 font-semibold">
              {currentUser.data.firstName} {currentUser.data.lastName}
            </div>
            <div className="text-sm text-gray-500">
              Steward for {network?.data.name || 'CommunityShare'}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex flex-col text-center justify-center bg-accent rounded-xl p-6">
          <div className="text-2xl text-white font-bold mb-2">
            Are you interested?
          </div>
          <div className="text-base text-white mb-4">
            That's wonderful! Connect with {educator.data.firstName} to participate, ask
            questions, offer feedback, or just to refer someone you think might be a good fit.
          </div>
          <div className="bg-white rounded-lg w-56 text-center py-3 m-auto">
            <div className="text-base text-accent uppercase font-bold">
              Respond or Decline
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8 space-y-4">
        <div className="text-2xl font-bold">
          Details
        </div>
        <div className="bg-white rounded-lg p-6 space-y-8">
          <div className="text-2xl font-semibold">
            {data.summary.title}
          </div>
          <div>
            <div className="text-xl font-semibold">
              Summary
            </div>
            <div className="text-base text-gray-500">
              <ClampableText markup>
                {data.summary.text}
              </ClampableText>
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold">
              {data.date.title}
            </div>
            <div className="text-gray-500">
              <ClampableText markup>
                {data.date.text}
              </ClampableText>
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold">
              Collaboration Options
            </div>
            {!data.virtual && !data.inPerson && (
              <div>
                <div className="text-gray-700">
                  (not provided)
                </div>
                <div className="text-xs text-gray-500">
                  {distance <= 1 ? '1 mile away' : `${distance} miles away`}
                </div>
              </div>
            )}
            {data.virtual && (
              <div>
                <div className="text-gray-700 mb-1">
                  Virtual
                </div>
              </div>
            )}
            {data.inPerson && (
              <div>
                <div className="text-gray-700">
                  In-person
                </div>
                <div className="text-xs text-gray-500">
                  {distance <= 1 ? '1 mile away' : `${distance} miles away`}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="text-2xl font-bold">
          Activity
        </div>
        <div className="bg-white rounded-lg p-6">
          <div className="text-xl font-semibold">
            {data.activity.title}
          </div>
          <div className="text-gray-500">
            {data.activity.text}
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex flex-col text-center justify-center bg-accent rounded-xl p-6">
          <div className="text-2xl text-white font-bold mb-2">
            Are you interested?
          </div>
          <div className="text-base text-white mb-4">
            That's wonderful! Connect with {educator.data.firstName} to participate, ask
            questions, offer feedback, or just to refer someone you think might be a good fit.
          </div>
          <div className="bg-white rounded-lg w-56 text-center py-3 m-auto">
            <div className="text-base text-accent uppercase font-bold">
              Respond or Decline
            </div>
          </div>
        </div>
      </div>
      <div className="h-1 bg-accent w-full"/>
    </div>
  );
};
