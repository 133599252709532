import React from 'react';
import { Helmet } from 'react-helmet-async';
import Stewards from './Stewards';
import useRouter from '../../hooks/useRouter';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Results from '../_common_results/Results';
import Steward from './Steward';
import Filters from '../../forms/filters_projects';
import { BuildingOffice2Icon, FolderIcon } from '@heroicons/react/24/outline';
import MobileNavigation from './MobileNavigation';
import NotAuthorizedEmptyState from '../manage-networks/NotAuthorizedEmptyState';
import { useUser } from '@lore/auth';

export default function Layout(props) {
  const router = useRouter();
  const currentUser = useUser();

  if (!currentUser.data.isStaff) {
    return (
      <NotAuthorizedEmptyState />
    );
  }

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Manage: Stewards</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Manage']}
        title="Stewards"
        description={(
          <>
            This page provides a list of all stewards as well as which networks they have access to.
          </>
        )}
      />
      <Filters router={router} />
      <Stewards router={router}>
        {function(result) {
          return (
            <Results result={result} columns={1} resource="stewards" emptyIcon={BuildingOffice2Icon}>
              {function(user, index) {
                return (
                  <Steward
                    key={index}
                    user={user}
                  />
                );
              }}
            </Results>
          );
        }}
      </Stewards>
    </div>
  );
}
