import React from 'react';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import User from './User';
import { useConnect } from '@lore/query-connect';
import _ from 'lodash';
import mapResponse from '../../utils/mapResponse';

export default function OfferUser(props) {
  const {
    offer,
    user,
    children
  } = props;

  const launch = useDialogLauncher();

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const claim = _.find(claims.data, function(claim) {
    return claim.data.userId === user.id;
  });

  const selected = !!claim;

  function onClick() {
    launch(DialogManager.UserView.Config(user.id, {
      modelName: 'offer',
      modelId: offer.id
    }));
  }

  return (
    <User
      user={user}
      selected={selected}
      footer={claim ? mapResponse(claim.data.response) : undefined}
      children={children}
      onClick={onClick}
    />
  );
}
