import React from 'react';
import classNames from 'classnames';
import useNetwork from '../hooks/useNetwork';
import Tooltip from './Tooltip';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import useDialogLauncher from '../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../dialogs_new/DialogManager';

export default function Network(props) {
  const { showName = true, tooltip = false } = props;

  const network = useNetwork();
  const launch = useDialogLauncher();

  const src = network?.data.logo || '';
  const name = network?.data.name || 'None';

  function onClick() {
    launch(DialogManager.SelectNetwork.Config(network?.id));
  }

  if (tooltip) {
    return (
      <div className={classNames(
        'flex items-center space-x-3'
      )}>
        <Tooltip tooltip={(
          <>
            {/*<div className="text-base text-cs-gray-500 mb-1">*/}
            <div className="text-base text-cs-gray-500 mb-2">
              Your Network
            </div>
            <div className="text-base text-cs-gray-900">
              {name}
            </div>
          </>
        )} icon={false} place="right">
        <div className={classNames(
          'flex items-center h-14 w-14 overflow-hidden',
          'shadow-cs-flat -m-px border rounded-cs-10',
          'border-cs-gray-200 bg-white',
          'hover:bg-cs-orange-50 cursor-pointer'
        )} onClick={onClick}>
          {src ? (
            <img className="h-11 w-11 object-contain m-auto" src={src} alt="logo" />
          ) : (
            <NoSymbolIcon className="h-6 w-6 object-contain m-auto text-cs-gray-400" />
          )}
        </div>
        </Tooltip>
      </div>
    );
  }

  if (src) {
    return (
      <div className={classNames(
        'flex items-center space-x-3'
      )}>
        <div className={classNames(
          'flex items-center h-14 w-14 overflow-hidden',
          'shadow-cs-flat -m-px border rounded-cs-10',
          'border-cs-gray-200 bg-white',
          'hover:bg-cs-orange-50 cursor-pointer'
        )} onClick={onClick}>
          <img className="h-11 w-11 object-contain m-auto" src={src} alt="logo" />
        </div>
        {/*{showName && (*/}
        {/*  <Link to="/my-network" className={classNames(*/}
        {/*    'flex-1 text-lg font-semibold',*/}
        {/*    isSelected ? 'text-cs-orange-500' : 'text-cs-gray-900'*/}
        {/*  )}>*/}
        {/*    {name}*/}
        {/*  </Link>*/}
        {/*)}*/}
        {showName && (
          <div className={classNames(
            'flex-1 text-lg font-semibold',
            'text-cs-gray-900',
            'line-clamp-2'
          )}>
            {name}
          </div>
        )}
      </div>
    );
  }

  return null;
};
