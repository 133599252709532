import React from 'react';
import classNames from 'classnames';
import Header from './Header';
import District from './District';
import Networks from './Networks';
import Projects from './Projects';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Educators from './Educators';

export default function NetworkSite(props) {
  const { site, network, className, children } = props;

  const networkSite = useConnect('networkSite.first', {
    where: {
      eager: {
        $where: {
          siteId: site.id,
          networkId: network?.id
        }
      }
    }
  });

  if (networkSite.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      // 'hover:bg-cs-orange-50 cursor-pointer',
      className
    )}>
      <div className="flex flex-col py-4 px-4 space-y-2">
        <Header networkSite={networkSite} site={site} />
        <div className="flex flex-col sm:flex-row items-start justify-between">
          <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
            <Educators site={site} />
            <Networks site={site} />
            <District site={site} />
          </div>
          <Projects site={site}/>
        </div>
      </div>
      {children}
    </div>
  );
}
