import React from 'react';
import { useConnect } from '@lore/query-connect';
import REQUIRED_FIELDS from '../../events/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function StudentsYear(props) {
  const { network, schoolStart, schoolEnd, titleOnly } = props;

  const allEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          networkId: network?.id,
          completed: true,
          students: {
            $gte: 0
          },
          createdAt: {
            $lte: schoolEnd
          }
        }
      },
    },
    pagination: {
      pageSize: 1000
    }
  });

  const newEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          networkId: network?.id,
          completed: true,
          students: {
            $gte: 0
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      },
    },
    pagination: {
      pageSize: 1000
    }
  });

  if (
    allEvents.state === PayloadStates.FETCHING ||
    newEvents.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Students Impacted"
      />
    );
  }

  const totalCount = _.reduce(allEvents.data, function(result, event) {
    return result + event.data.students;
  }, 0);

  const newCount = _.reduce(newEvents.data, function(result, event) {
    return result + event.data.students;
  }, 0);

  return (
    <DeltaMetric
      name="Students Impacted"
      value={totalCount}
      delta={newCount}
      titleOnly={titleOnly}
    />
  );
}
