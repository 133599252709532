import React from 'react';
import { Outlet, Route, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom';
import { parse, stringify } from 'query-string';

/**
 * The AuthenticatedRoute provides an easy way to redirect the user
 * to a login experience if we don't know who they are.
 */

import UserIsAuthenticated from './src/decorators/UserIsAuthenticated';
import Master from './src/pages/Master';
import Layout from './src/pages/Layout';

/**
 * Routes are used to declare your view hierarchy
 * See: https://reacttraining.com/react-router/web/guides/quick-start
 */

import Login from './src/pages/Login';
import Signup from './src/pages/Signup';
import Logout from './src/pages/Logout';
import AuthCallback from './src/pages/AuthCallback';

import SelectCommunityLayout from './src/pages/select-community/Layout';

import NotAuthorized from './src/pages/unauthorized/Layout';
import NotFoundPage from './src/pages/NotFoundPage';

/*
 *  routes
 */

import DashboardRedirectLayout from './src/pages/dashboard-redirect/Layout';
import DashboardOverviewStudentsLayout from './src/pages/dashboard-overview/Layout.students';
import DashboardOverviewEducatorsLayout from './src/pages/dashboard-overview/Layout.educators';
import DashboardOverviewPartnersLayout from './src/pages/dashboard-overview/Layout.partners';
import DashboardOverviewProjectsLayout from './src/pages/dashboard-overview/Layout.projects';
import DashboardOverviewEventsLayout from './src/pages/dashboard-overview/Layout.events';
import DashboardOverviewOffersLayout from './src/pages/dashboard-overview/Layout.offers';
import DashboardOverviewEvaluationsLayout from './src/pages/dashboard-overview/Layout.evaluations';
import DashboardOverviewSchoolsLayout from './src/pages/dashboard-overview/Layout.schools';
import DashboardOverviewOrganizationsLayout from './src/pages/dashboard-overview/Layout.organizations';

import FeedLayout from './src/pages/feed/Layout';
import FeedMessagesLayout from './src/pages/feed-filtered/messages/Layout';
import FeedProjectsLayout from './src/pages/feed-filtered/projects/Layout';
import FeedNotesAllLayout from './src/pages/feed-filtered/notes-all/Layout';
import FeedEvaluationsAllLayout from './src/pages/feed-filtered/evaluations-all/Layout';
import FeedInvitationsAllLayout from './src/pages/feed-filtered/invitations-all/Layout';
import FeedEventsLayout from './src/pages/feed-filtered/events/Layout';
import FeedOffersLayout from './src/pages/feed-filtered/offers/Layout';
import FeedPartnersLayout from './src/pages/feed-filtered/partners/Layout';
import FeedEducatorsLayout from './src/pages/feed-filtered/educators/Layout';

import DashboardYearStudentsLayout from './src/pages/dashboard-year/Layout.students';
import DashboardYearEducatorsLayout from './src/pages/dashboard-year/Layout.educators';
import DashboardYearPartnersLayout from './src/pages/dashboard-year/Layout.partners';
import DashboardYearProjectsLayout from './src/pages/dashboard-year/Layout.projects';
import DashboardYearEventsLayout from './src/pages/dashboard-year/Layout.events';
import DashboardYearOffersLayout from './src/pages/dashboard-year/Layout.offers';
import DashboardYearEvaluationsLayout from './src/pages/dashboard-year/Layout.evaluations';
import DashboardYearSchoolsLayout from './src/pages/dashboard-year/Layout.schools';
import DashboardYearOrganizationsLayout from './src/pages/dashboard-year/Layout.organizations';

import SitesLayout from './src/pages/sites/Layout';
import SitesUnlinkedProjectsLayout from './src/pages/sites-filtered/unlinked-projects/Layout';
import SitesUnlinkedEventsLayout from './src/pages/sites-filtered/unlinked-events/Layout';
import SitesMissingRolesLayout from './src/pages/sites-filtered/missing-roles/Layout';

import ManageNetworksLayout from './src/pages/manage-networks/Layout';
import ManageSitesLayout from './src/pages/manage-sites/Layout';
import ManageStaffLayout from './src/pages/manage-staff/Layout';
import ManageStewardsLayout from './src/pages/manage-stewards/Layout';

import ProjectsLayout from './src/pages/projects/Layout';
import StarredProjectsLayout from './src/pages/projects-filtered/starred/Layout';
import ExpiringProjectsLayout from './src/pages/projects-filtered/expiring/Layout';
import PartnersInvitedProjectsLayout from './src/pages/projects-filtered/partners-invited/Layout';
import PartnersConnectedProjectsLayout from './src/pages/projects-filtered/partners-connected/Layout';
import DraftProjectsLayout from './src/pages/projects-filtered/incomplete/Layout';
import NewProjectsLayout from './src/pages/projects-filtered/new/Layout';
import ActiveProjectsLayout from './src/pages/projects-filtered/active/Layout';
import ProjectsWantsContactLayout from './src/pages/projects-filtered/wants-contact/Layout';
import ProjectsResourcesNeededLayout from './src/pages/projects-filtered/resources-needed/Layout';
import ProjectsFundingRequestedLayout from './src/pages/projects-filtered/funding-requested/Layout';
import ProjectsExpiredNotClosedLayout from './src/pages/projects-filtered/expired-not-closed/Layout';
import ProjectsMissingEducatorEvaluationLayout from './src/pages/projects-filtered/missing-educator-evaluation/Layout';
import ProjectsCompletedLayout from './src/pages/projects-filtered/completed/Layout';
import ProjectsSchoolYearLayout from './src/pages/projects-filtered/school-year/Layout';

import EventsLayout from './src/pages/events/Layout';
import StarredEventsLayout from './src/pages/events-filtered/starred/Layout';
import PartnersEventsLayout from './src/pages/events-filtered/partners/Layout';
import DraftEventsLayout from './src/pages/events-filtered/draft/Layout';
import NewEventsLayout from './src/pages/events-filtered/new/Layout';
import ActiveEventsLayout from './src/pages/events-filtered/active/Layout';
import SoonEventsLayout from './src/pages/events-filtered/soon/Layout';
import EventsExpiredNotClosedLayout from './src/pages/events-filtered/expired-not-closed/Layout';
import EventsMissingEducatorEvaluationLayout from './src/pages/events-filtered/missing-educator-evaluation/Layout';
import EventsCompletedLayout from './src/pages/events-filtered/completed/Layout';
import EventsSchoolYearLayout from './src/pages/events-filtered/school-year/Layout';

import OffersLayout from './src/pages/offers/Layout';
import StarredOffersLayout from './src/pages/offers-filtered/starred/Layout';
import NewOffersLayout from './src/pages/offers-filtered/new/Layout';
import ActiveOffersLayout from './src/pages/offers-filtered/active/Layout';
import OffersSchoolYearLayout from './src/pages/offers-filtered/school-year/Layout';

import PartnersLayout from './src/pages/partners/Layout';
import PartnersEngagementLayout from './src/pages/partners-engagement/Layout';
import StarredPartnersLayout from './src/pages/partners-filtered/starred/Layout';
import IncompletePartnersLayout from './src/pages/partners-filtered/incomplete/Layout';
import PartnersRelatedLayout from './src/pages/partners-filtered/related/Layout';
import PartnersNewLayout from './src/pages/partners-filtered/new/Layout';
import PartnersInactiveLayout from './src/pages/partners-filtered/inactive/Layout';
import PartnersSleepingLayout from './src/pages/partners-filtered/sleeping/Layout';
import PartnersNoInteractionsLayout from './src/pages/partners-filtered/no-interactions/Layout';
import PartnersMultipleEvaluationsLayout from './src/pages/partners-filtered/multiple-evaluations/Layout';
import PartnersMultipleHoursLayout from './src/pages/partners-filtered/multiple-hours/Layout';
import PartnersMultipleStudentsLayout from './src/pages/partners-filtered/multiple-students/Layout';
import PartnersMultipleDonationsLayout from './src/pages/partners-filtered/multiple-donations/Layout';

import EducatorsLayout from './src/pages/educators/Layout';
import EducatorsEngagementLayout from './src/pages/educators-engagement/Layout';
import StarredEducatorsLayout from './src/pages/educators-filtered/starred/Layout';
import IncompleteEducatorsLayout from './src/pages/educators-filtered/incomplete/Layout';
import EducatorsRelatedLayout from './src/pages/educators-filtered/related/Layout';
import EducatorsRelatedAffiliationLayout from './src/pages/educators-filtered/related-affiliation/Layout';
import EducatorsNewLayout from './src/pages/educators-filtered/new/Layout';
import EducatorsSleepingLayout from './src/pages/educators-filtered/sleeping/Layout';
import EducatorsInactiveLayout from './src/pages/educators-filtered/inactive/Layout';
import EducatorsNoneCreatedLayout from './src/pages/educators-filtered/none-created/Layout';
import EducatorsMultipleCompletedLayout from './src/pages/educators-filtered/multiple-completed/Layout';

import SettingsTeamLayout from './src/pages/settings-team/Layout';

import EvaluationsRedirectLayout from './src/pages/evaluations-redirect/Layout';

import EvaluationsProjectEducatorLayout from './src/pages/evaluations-project-educators/Layout';
import EvaluationsProjectEducatorCompleteLayout from './src/pages/evaluations-project-educators-filtered/complete/Layout';
import EvaluationsProjectEducatorPendingLayout from './src/pages/evaluations-project-educators-filtered/pending/Layout';
import EvaluationsProjectEducatorPhotosLayout from './src/pages/evaluations-project-educators-filtered/photos/Layout';
import EvaluationsProjectEducatorTestimonialsLayout from './src/pages/evaluations-project-educators-filtered/testimonials/Layout';
import EvaluationsProjectEducatorStoriesLayout from './src/pages/evaluations-project-educators-filtered/stories/Layout';

import EvaluationsProjectPartnerLayout from './src/pages/evaluations-project-partners/Layout';
import EvaluationsProjectPartnerCompleteLayout from './src/pages/evaluations-project-partners-filtered/complete/Layout';
import EvaluationsProjectPartnerPendingLayout from './src/pages/evaluations-project-partners-filtered/pending/Layout';
import EvaluationsProjectPartnerContributionsLayout from './src/pages/evaluations-project-partners-filtered/contributions/Layout';
import EvaluationsProjectPartnerTestimonialsLayout from './src/pages/evaluations-project-partners-filtered/testimonials/Layout';

import EvaluationsEventEducatorLayout from './src/pages/evaluations-event-educators/Layout';
import EvaluationsEventEducatorCompleteLayout from './src/pages/evaluations-event-educators-filtered/complete/Layout';
import EvaluationsEventEducatorPendingLayout from './src/pages/evaluations-event-educators-filtered/pending/Layout';
import EvaluationsEventEducatorPhotosLayout from './src/pages/evaluations-event-educators-filtered/photos/Layout';
import EvaluationsEventEducatorTestimonialsLayout from './src/pages/evaluations-event-educators-filtered/testimonials/Layout';
import EvaluationsEventEducatorStoriesLayout from './src/pages/evaluations-event-educators-filtered/stories/Layout';

import EvaluationsEventPartnerLayout from './src/pages/evaluations-event-partners/Layout';
import EvaluationsEventPartnerCompleteLayout from './src/pages/evaluations-event-partners-filtered/complete/Layout';
import EvaluationsEventPartnerPendingLayout from './src/pages/evaluations-event-partners-filtered/pending/Layout';
import EvaluationsEventPartnerTestimonialsLayout from './src/pages/evaluations-event-partners-filtered/testimonials/Layout';

import SettingsScreeningLayout from './src/pages/settings-screening/Layout';
import SettingsTrainingLayout from './src/pages/settings-training/Layout';
import SettingsBannerLayout from './src/pages/settings-banner/Layout';
import SettingsGrantsLayout from './src/pages/settings-grants/Layout';

import LayoutSidebarNavigation from './src/pages/explore/LayoutSidebarNavigation';

// Redirect decorator that will also transfer the networks query param
function _redirect(pathname, request) {
  let url = pathname;
  const tokens = request.url.split('?')
  const query = parse(tokens[1]);

  if (query.networks) {
    url = `${url}?networks=${query.networks}`;
  }

  return redirect(url);
}

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={(
      <>
        {/*<RemoveLoadingScreen />*/}
        <Outlet />
      </>
    )}>
      {/* Redirects */}
      {/*<Route index loader={() => redirect(`/select-community`)} />*/}
      <Route index loader={({ params, request }) => _redirect(`/partners`, request)} />
      <Route path="/dashboard" loader={({ params, request }) => _redirect(`/dashboard/redirect`, request)} />
      <Route path="/explore" loader={({ params, request }) => _redirect(`/projects`, request)} />
      <Route path="/evaluations" loader={({ params, request }) => _redirect(`/evaluations/redirect`, request)} />
      <Route path="/team" loader={({ params, request }) => _redirect(`/settings/team`, request)} />
      <Route path="/settings" loader={({ params, request }) => _redirect(`/settings/team`, request)} />
      <Route path="/feed" loader={({ params, request }) => _redirect(`/feed/1/all`, request)} />
      <Route path="/manage" loader={({ params, request }) => _redirect(`/manage/networks`, request)} />

      {/* Routes */}
      <Route path="/login" Component={Login} />
      <Route path="/signup" Component={Signup} />
      <Route path="/logout" Component={Logout} />
      <Route path="/auth/callback" Component={AuthCallback} />
      <Route path="/unauthorized" Component={NotAuthorized} />

      <Route Component={UserIsAuthenticated}>
        <Route Component={Master}>
          <Route path="/select-community" Component={SelectCommunityLayout} />
        </Route>
      </Route>

      {/* Authenticated Routes w/ Common Layout */}
      <Route Component={UserIsAuthenticated}>
        <Route Component={Master}>
          <Route Component={Layout}>
            {/* Dashboard */}
            <Route element={<LayoutSidebarNavigation type="dashboard"/>}>
              <Route path="/dashboard/redirect" Component={DashboardRedirectLayout} />
              <Route path="/dashboard/overview/students" Component={DashboardOverviewStudentsLayout} />
              <Route path="/dashboard/overview/educators" Component={DashboardOverviewEducatorsLayout} />
              <Route path="/dashboard/overview/partners" Component={DashboardOverviewPartnersLayout} />
              <Route path="/dashboard/overview/projects" Component={DashboardOverviewProjectsLayout} />
              <Route path="/dashboard/overview/events" Component={DashboardOverviewEventsLayout} />
              <Route path="/dashboard/overview/offers" Component={DashboardOverviewOffersLayout} />
              <Route path="/dashboard/overview/evaluations" Component={DashboardOverviewEvaluationsLayout} />
              <Route path="/dashboard/overview/schools" Component={DashboardOverviewSchoolsLayout} />
              <Route path="/dashboard/overview/organizations" Component={DashboardOverviewOrganizationsLayout} />

              <Route path="/dashboard/year/:year/students" Component={DashboardYearStudentsLayout} />
              <Route path="/dashboard/year/:year/educators" Component={DashboardYearEducatorsLayout} />
              <Route path="/dashboard/year/:year/partners" Component={DashboardYearPartnersLayout} />
              <Route path="/dashboard/year/:year/projects" Component={DashboardYearProjectsLayout} />
              <Route path="/dashboard/year/:year/events" Component={DashboardYearEventsLayout} />
              <Route path="/dashboard/year/:year/offers" Component={DashboardYearOffersLayout} />
              <Route path="/dashboard/year/:year/evaluations" Component={DashboardYearEvaluationsLayout} />
              <Route path="/dashboard/year/:year/schools" Component={DashboardYearSchoolsLayout} />
              <Route path="/dashboard/year/:year/organizations" Component={DashboardYearOrganizationsLayout} />
            </Route>

            {/* Feed */}
            <Route element={<LayoutSidebarNavigation type="feed"/>}>
              <Route path="/feed" Component={FeedLayout} />
              <Route path="/feed/:weeks/all" Component={FeedLayout} />
              <Route path="/feed/:weeks/messages" Component={FeedMessagesLayout} />
              <Route path="/feed/:weeks/projects" Component={FeedProjectsLayout} />
              <Route path="/feed/:weeks/events" Component={FeedEventsLayout} />
              <Route path="/feed/:weeks/offers" Component={FeedOffersLayout} />
              <Route path="/feed/:weeks/invitations" Component={FeedInvitationsAllLayout} />
              <Route path="/feed/:weeks/partners" Component={FeedPartnersLayout} />
              <Route path="/feed/:weeks/educators" Component={FeedEducatorsLayout} />
              <Route path="/feed/:weeks/notes" Component={FeedNotesAllLayout} />
              <Route path="/feed/:weeks/evaluations" Component={FeedEvaluationsAllLayout} />
            </Route>

            {/* Evaluations */}
            <Route path="/evaluations/redirect" Component={EvaluationsRedirectLayout} />
            <Route element={<LayoutSidebarNavigation type="evaluations"/>}>
              <Route path="/evaluations/:year/projects/educators" Component={EvaluationsProjectEducatorLayout} />
              <Route path="/evaluations/:year/projects/educators/complete" Component={EvaluationsProjectEducatorCompleteLayout} />
              <Route path="/evaluations/:year/projects/educators/pending" Component={EvaluationsProjectEducatorPendingLayout} />
              <Route path="/evaluations/:year/projects/educators/photos" Component={EvaluationsProjectEducatorPhotosLayout} />
              <Route path="/evaluations/:year/projects/educators/testimonials" Component={EvaluationsProjectEducatorTestimonialsLayout} />
              <Route path="/evaluations/:year/projects/educators/stories" Component={EvaluationsProjectEducatorStoriesLayout} />

              <Route path="/evaluations/:year/projects/partners" Component={EvaluationsProjectPartnerLayout} />
              <Route path="/evaluations/:year/projects/partners/complete" Component={EvaluationsProjectPartnerCompleteLayout} />
              <Route path="/evaluations/:year/projects/partners/pending" Component={EvaluationsProjectPartnerPendingLayout} />
              <Route path="/evaluations/:year/projects/partners/contributions" Component={EvaluationsProjectPartnerContributionsLayout} />
              <Route path="/evaluations/:year/projects/partners/testimonials" Component={EvaluationsProjectPartnerTestimonialsLayout} />

              <Route path="/evaluations/:year/events/educators" Component={EvaluationsEventEducatorLayout} />
              <Route path="/evaluations/:year/events/educators/complete" Component={EvaluationsEventEducatorCompleteLayout} />
              <Route path="/evaluations/:year/events/educators/pending" Component={EvaluationsEventEducatorPendingLayout} />
              <Route path="/evaluations/:year/events/educators/photos" Component={EvaluationsEventEducatorPhotosLayout} />
              <Route path="/evaluations/:year/events/educators/testimonials" Component={EvaluationsEventEducatorTestimonialsLayout} />
              <Route path="/evaluations/:year/events/educators/stories" Component={EvaluationsEventEducatorStoriesLayout} />

              <Route path="/evaluations/:year/events/partners" Component={EvaluationsEventPartnerLayout} />
              <Route path="/evaluations/:year/events/partners/complete" Component={EvaluationsEventPartnerCompleteLayout} />
              <Route path="/evaluations/:year/events/partners/pending" Component={EvaluationsEventPartnerPendingLayout} />
              <Route path="/evaluations/:year/events/partners/testimonials" Component={EvaluationsEventPartnerTestimonialsLayout} />
            </Route>

            {/* Settings */}
            <Route element={<LayoutSidebarNavigation type="settings"/>}>
              <Route path="/settings/team" Component={SettingsTeamLayout} />
              <Route path="/settings/screening" Component={SettingsScreeningLayout} />
              <Route path="/settings/training" Component={SettingsTrainingLayout} />
              <Route path="/settings/banner" Component={SettingsBannerLayout} />
              <Route path="/settings/grants" Component={SettingsGrantsLayout} />
            </Route>

            {/* Sites */}
            <Route element={<LayoutSidebarNavigation type="sites"/>}>
              <Route path="/sites" Component={SitesLayout} />
              <Route path="/sites/unlinked-projects" Component={SitesUnlinkedProjectsLayout} />
              <Route path="/sites/unlinked-events" Component={SitesUnlinkedEventsLayout} />
              <Route path="/sites/missing-roles" Component={SitesMissingRolesLayout} />
            </Route>

            {/* Networks */}
            <Route element={<LayoutSidebarNavigation type="manage"/>}>
              <Route path="/manage/networks" Component={ManageNetworksLayout} />
              <Route path="/manage/sites" Component={ManageSitesLayout} />
              <Route path="/manage/staff" Component={ManageStaffLayout} />
              <Route path="/manage/stewards" Component={ManageStewardsLayout} />
            </Route>

            {/* Projects */}
            <Route element={<LayoutSidebarNavigation type="projects"/>}>
              <Route path="/projects" Component={ProjectsLayout} />
              <Route path="/projects/starred" Component={StarredProjectsLayout} />
              <Route path="/projects/new" Component={NewProjectsLayout} />
              <Route path="/projects/draft" Component={DraftProjectsLayout} />
              <Route path="/projects/wants-contact" Component={ProjectsWantsContactLayout} />
              <Route path="/projects/partners-invited" Component={PartnersInvitedProjectsLayout} />
              <Route path="/projects/partners-connected" Component={PartnersConnectedProjectsLayout} />
              <Route path="/projects/active" Component={ActiveProjectsLayout} />
              <Route path="/projects/resources-needed" Component={ProjectsResourcesNeededLayout} />
              <Route path="/projects/funding-requested" Component={ProjectsFundingRequestedLayout} />
              <Route path="/projects/expiring" Component={ExpiringProjectsLayout} />
              <Route path="/projects/expired-not-closed" Component={ProjectsExpiredNotClosedLayout} />
              <Route path="/projects/missing-educator-evaluation" Component={ProjectsMissingEducatorEvaluationLayout} />
              <Route path="/projects/completed" Component={ProjectsCompletedLayout} />
              <Route path="/projects/school-year" Component={ProjectsSchoolYearLayout} />
            </Route>

            {/* Events */}
            <Route element={<LayoutSidebarNavigation type="events"/>}>
              <Route path="/events" Component={EventsLayout} />
              <Route path="/events/starred" Component={StarredEventsLayout} />
              <Route path="/events/new" Component={NewEventsLayout} />
              <Route path="/events/active" Component={ActiveEventsLayout} />
              <Route path="/events/draft" Component={DraftEventsLayout} />
              <Route path="/events/partners" Component={PartnersEventsLayout} />
              <Route path="/events/soon" Component={SoonEventsLayout} />
              <Route path="/events/expired-not-closed" Component={EventsExpiredNotClosedLayout} />
              <Route path="/events/missing-educator-evaluation" Component={EventsMissingEducatorEvaluationLayout} />
              <Route path="/events/completed" Component={EventsCompletedLayout} />
              <Route path="/events/school-year" Component={EventsSchoolYearLayout} />
            </Route>

            {/* Offers */}
            <Route element={<LayoutSidebarNavigation type="offers"/>}>
              <Route path="/offers" Component={OffersLayout} />
              <Route path="/offers/starred" Component={StarredOffersLayout} />
              <Route path="/offers/new" Component={NewOffersLayout} />
              <Route path="/offers/active" Component={ActiveOffersLayout} />
              <Route path="/offers/school-year" Component={OffersSchoolYearLayout} />
            </Route>

            {/* Partners */}
            <Route element={<LayoutSidebarNavigation type="partners"/>}>
              <Route path="/partners" Component={PartnersLayout} />
              <Route path="/partners/related" Component={PartnersRelatedLayout} />
              <Route path="/partners/engagement" Component={PartnersEngagementLayout} />
              <Route path="/partners/starred" Component={StarredPartnersLayout} />
              <Route path="/partners/new" Component={PartnersNewLayout} />
              <Route path="/partners/inactive" Component={PartnersInactiveLayout} />
              <Route path="/partners/sleeping" Component={PartnersSleepingLayout} />
              <Route path="/partners/incomplete" Component={IncompletePartnersLayout} />
              <Route path="/partners/no-interactions" Component={PartnersNoInteractionsLayout} />
              <Route path="/partners/multiple-evaluations" Component={PartnersMultipleEvaluationsLayout} />
              <Route path="/partners/multiple-hours" Component={PartnersMultipleHoursLayout} />
              <Route path="/partners/multiple-students" Component={PartnersMultipleStudentsLayout} />
              <Route path="/partners/multiple-donations" Component={PartnersMultipleDonationsLayout} />
            </Route>

            {/* Educators */}
            <Route element={<LayoutSidebarNavigation type="educators"/>}>
              <Route path="/educators" Component={EducatorsLayout} />
              <Route path="/educators/related" Component={EducatorsRelatedLayout} />
              <Route path="/educators/related-affiliation" Component={EducatorsRelatedAffiliationLayout} />
              <Route path="/educators/engagement" Component={EducatorsEngagementLayout} />
              <Route path="/educators/starred" Component={StarredEducatorsLayout} />
              <Route path="/educators/new" Component={EducatorsNewLayout} />
              <Route path="/educators/sleeping" Component={EducatorsSleepingLayout} />
              <Route path="/educators/inactive" Component={EducatorsInactiveLayout} />
              <Route path="/educators/incomplete" Component={IncompleteEducatorsLayout} />
              <Route path="/educators/none-created" Component={EducatorsNoneCreatedLayout} />
              <Route path="/educators/multiple-completed" Component={EducatorsMultipleCompletedLayout} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" Component={NotFoundPage} />
    </Route>
  )
);
