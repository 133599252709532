import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import DialogTemplate from '../_common_old/DialogTemplate';
import Form from './Form';
import Invite from './Invite';
import Confirmation from './Confirmation';
import useNetwork from '../../hooks/useNetwork';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

export default function Dialog(props) {
  const currentUser = useUser();
  const network = useNetwork();

  const [data, setData] = useState({
    setEmail: false,
    email: '',
    user: null,
    creatorId: currentUser.id
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('stewardInvitation.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      // props.onClose();
      // props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit(user) {
    mutation.mutate({
      networkId: network.id,
      email: data.email,
      creatorId: data.creatorId
    });
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          email: [
            validators.isRequired,
            validators.isEmail
          ]
        }}>
          {({errors, hasError}) => {
            if (success) {
              return (
                <Confirmation
                  onClose={props.onClose}
                />
              );
            }

            if (data.setEmail) {
              return (
                <Invite
                  data={data}
                  errors={errors}
                  showErrors={showErrors}
                  onChange={onChange}
                  onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                  onClose={props.onClose}
                  onBack={() => setData({
                    ...data,
                    email: '',
                    setEmail: false
                  })}
                />
              )
            }

            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                // onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onSubmit={() => hasError ? setShowErrors(true) : onChange('setEmail', true)}
                onClose={props.onClose}
                alert={error ? (
                  <RequestError request={request}/>
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : (false && success) ? (
                  <SuccessMessage>
                    Idea created.
                  </SuccessMessage>
                ) : null}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
