/**
 * Staging environment settings
 *
 * This file is where you define overrides for any of the config settings that
 * should only be applied in the staging environment.
 *
 * The production environment is defined as 'process.env.LORE_ENV=staging'
 */

export default {

  /**
   * To override a config setting, specify the name of the config file you
   * want to override, followed by the settings you want to change.
   */

  auth0: {
    clientID: 'OlIRhKWHrrLEo9aHG9y8ElEQdlbNyd82',
    redirectUri: 'https://steward-staging.communityshare.org/auth/callback',
  },

  connections: {
    default: {
      apiRoot: 'https://adminstaging.communityshare.cloud'
    }
  },

  custom: {
    platformUrl: function() {
      return `https://app-staging.communityshare.org`;
    }
  },

  intercom: {
    appId: 'x2vk07yc',
    companyId: 'staging-admin',
    companyName: 'Staging (Admin)'
  },

  raven: {
    environment: 'staging-admin',
    enabled: true
  },

  fullstory: {
    enabled: false
  },

  dialogs: {
    showTransitions: true
  }

}
